import Config from '../../app.config';

const GoogleMapsInit = () => {
  try {
    const googleMapScript = document.createElement('script');
    googleMapScript.defer = true;
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${
      Config.googleMapsApiKey
    }&language=en&libraries=places`;
    document.head.appendChild(googleMapScript);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Google Maps API init error: ', err);
  }
};

export default GoogleMapsInit;
