/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  analytics,
  isMobile,
  MembershipCancelledStatusId,
} from '../../utils/common';
import makeSelectAppointment from '../Appointment/selectors';
import makeSelectMembership from '../Membership/selectors';
import { logoutUser } from '../User/actions';
import { makeSelectUser } from '../User/selectors';

class Mainmenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.applyMarginBasedOnRoute = this.applyMarginBasedOnRoute.bind(this);
  }

  renderLink = (
    link,
    handleMenuClick,
    klass = 'nav__page-link nav__page-link--large',
  ) => {
    if (link.newTab) {
      return (
        <div key={link.id} className={klass}>
          <a
            target="_blank"
            href={link.href}
            data-testid={link['data-testid']}
            onClick={() => {
              handleMenuClick(link);
              analytics(
                `Menu - ${link.segmentEvent}`,
                {
                  'Logged In': this.isLoggedIn() ? 'Yes' : 'No',
                },
                true,
              );
            }}
          >
            {link.label}
          </a>
        </div>
      );
    }
    return (
      <div key={link.id} className={klass}>
        <Link
          href={link.href}
          to={link.href}
          data-testid={link['data-testid']}
          onClick={() => {
            handleMenuClick(link);
            analytics(
              `Menu - ${link.segmentEvent}`,
              {
                'Logged In': this.isLoggedIn() ? 'Yes' : 'No',
              },
              true,
            );
          }}
        >
          {link.label}
        </Link>
      </div>
    );
  };

  menuItemsGeneral = [
    {
      id: 1,
      label: 'Book a Massage',
      href: '/appointment/locations',
      'data-testid': 'btn_squeeze_book_a_massage_main_menu_guest_user',
      segmentEvent: 'Book a Massage',
    },
    {
      id: 2,
      label: 'Own a Squeeze',
      href: '/franchising',
      segmentEvent: 'Book a Massage',
    },
    { id: 3, label: 'Pricing', href: '/pricing', segmentEvent: 'Pricing' },
    {
      id: 4,
      label: 'Locations',
      href: '/appointment/locations?purchase_funnel=false',
      segmentEvent: 'Locations',
    },
    {
      id: 5,
      label: 'Therapists',
      href: '/therapist/list',
      segmentEvent: 'Therapists',
    },
    {
      id: 6,
      label: 'Become a Member',
      href: '/appointment/locations?purchase_funnel=false',
      segmentEvent: 'Become a Member',
    },
    {
      id: 7,
      label: 'Gift Cards',
      href: '/gift',
      segmentEvent: 'Gift Cards',
    },
  ];

  menuItemSub = [
    {
      id: 8,
      label: 'About Us',
      href: '/about-us',
      segmentEvent: 'About Us',
    },
    // {
    //   id: 9,
    //   label: 'Gallery',
    //   href: '/gallery',
    //   segmentEvent: 'Gallery',
    // },
    // {
    //   id: 10,
    //   label: 'Press',
    //   href: '/press',
    //   segmentEvent: 'Press',
    // },
    {
      id: 11,
      label: 'Careers',
      href: '/careers',
      segmentEvent: 'Careers',
    },
    // {
    //   id: 11,
    //   label: 'Careers',
    //   href: '/new-careers',
    //   segmentEvent: 'Careers',
    // },
    // {
    //   id: 12,
    //   label: 'FAQ',
    //   href:
    //     'https://squeezemassage.zendesk.com/hc/en-us/sections/360002009952-FAQs',
    //   segmentEvent: 'FAQ',
    //   newTab: true,
    // },
    {
      id: 13,
      label: 'Help',
      href: 'https://squeezemassage.zendesk.com/hc/en-us',
      segmentEvent: 'Help',
      newTab: true,
    },
    {
      id: 14,
      label: 'Login',
      href: '/login',
      'data-testid': 'btn_squeeze_login_main_menu',
      segmentEvent: 'Login',
    },
  ];

  menuItemsUser = [
    {
      id: 1,
      label: 'Book a Massage',
      href: '/appointment/locations',
      'data-testid': 'btn_squeeze_book_a_massage_main_menu_regular_user',
      segmentEvent: 'Book a Massage',
    },
    {
      id: 2,
      label: 'Own a Squeeze',
      href: '/franchising',
      segmentEvent: 'Book a Massage',
    },
    {
      id: 3,
      label: 'My Appointments',
      href: '/appointments',
      segmentEvent: 'My Appointments',
    },
    {
      id: 4,
      label: 'My Profile',
      href: '/profile',
      segmentEvent: 'My Profile',
    },
    {
      id: 5,
      label: 'My Preferences',
      href: '/preferences?from=menu',
      segmentEvent: 'My Preferences',
    },
    {
      id: 6,
      label: 'My Therapists',
      href: '/my-therapists',
      segmentEvent: 'My Therapists',
    },
    {
      id: 7,
      label: 'Become a Member',
      href: '/appointment/locations?purchase_funnel=false',
      segmentEvent: 'Become a Member',
    },
    {
      id: 8,
      label: 'Gift Cards',
      href: '/gift',
      segmentEvent: 'Gift Cards',
    },
    {
      id: 9,
      label: 'Invite Friends',
      href: '/invite',
      segmentEvent: 'Invite Friends',
    },
  ];

  menuItemsMember = [
    {
      id: 1,
      label: 'Book a Massage',
      href: '/appointment/locations',
      'data-testid': 'btn_squeeze_book_a_massage_main_menu_membership_user',
      segmentEvent: 'Book a Massage',
    },
    {
      id: 2,
      label: 'Own a Squeeze',
      href: '/franchising',
      segmentEvent: 'Book a Massage',
    },
    {
      id: 3,
      label: 'My Appointments',
      href: '/appointments',
      segmentEvent: 'My Appointments',
    },
    {
      id: 4,
      label: 'My Membership',
      href: '/membership',
      segmentEvent: 'My Membership',
    },
    {
      id: 5,
      label: 'My Profile',
      href: '/profile',
      segmentEvent: 'My Profile',
    },
    {
      id: 6,
      label: 'My Preferences',
      href: '/preferences?from=menu',
      segmentEvent: 'My Preferences',
    },
    {
      id: 7,
      label: 'My Therapists',
      href: '/my-therapists',
      segmentEvent: 'My Therapists',
    },
    {
      id: 8,
      label: 'Gift Cards',
      href: '/gift',
      segmentEvent: 'Gift Cards',
    },
    {
      id: 9,
      label: 'Invite Friends',
      href: '/invite',
      segmentEvent: 'Invite Friends',
    },
  ];

  menuMemberItemSub = [
    {
      id: 12,
      label: 'Locations',
      href: '/appointment/locations?purchase_funnel=false',
      segmentEvent: 'Locations',
    },
    {
      id: 13,
      label: 'Meet Our Therapists',
      href: '/therapist/list',
    },
    {
      id: 14,
      label: 'Pricing',
      href: this.isMember() ? '/membership' : '/pricing',
      segmentEvent: 'Pricing',
    },
    {
      id: 16,
      label: 'Help',
      href: 'https://squeezemassage.zendesk.com/hc/en-us',
      segmentEvent: 'FAQ',
      newTab: true,
    },
  ];

  isLoggedIn() {
    const {
      user: { userData },
    } = this.props;
    return !isEmpty(userData);
  }

  isMember() {
    const {
      user: { userData },
      membershipData: { membershipDetails },
    } = this.props;
    const activeMembership =
      membershipDetails && membershipDetails.status === 'active';
    return (
      Number(userData.has_membership) === 1 ||
      activeMembership ||
      (membershipDetails.entity_status_id &&
        Number(
          membershipDetails.entity_status_id !== MembershipCancelledStatusId,
        ))
    );
  }

  shouldRenderMyTherapist() {
    const { myStatus, fetchingStatus } = this.props.appointmentData;
    const completeAppointmentsCount =
      !fetchingStatus && myStatus && myStatus.hasCompletedAppointments;
    return completeAppointmentsCount;
  }

  renderAnonymousMenu() {
    return (
      <React.Fragment>
        {this.menuItemsGeneral.map(item =>
          this.renderLink(item, this.props.handleMenuClick),
        )}
        {this.menuItemSub.map((item, index) => {
          let klass = 'nav__page-link';
          if (index === 0) {
            klass += ' nav__page-link-first';
          }
          return this.renderLink(item, this.props.handleMenuClick, klass);
        })}
      </React.Fragment>
    );
  }

  renderMemberMenu() {
    const optionsToMap =
      this.isLoggedIn() && !this.isMember()
        ? this.menuItemsUser
        : this.menuItemsMember;
    return (
      <React.Fragment>
        {optionsToMap.map(item => {
          if (
            !(item.label === 'My Therapists' && !this.shouldRenderMyTherapist())
          ) {
            return this.renderLink(item, this.props.handleMenuClick);
          }
          return <div key={item.id} />;
        })}
        <h3
          className="phone-only nav__page-link--large logOutMobileMenu"
          onClick={() => this.props.logoutUser(this.props.history)}
        >
          Log out
        </h3>
        {this.menuMemberItemSub.map((item, index) => {
          let klass = 'nav__page-link';
          if (index === 0) {
            klass += ' nav__page-link-first';
          }
          return this.renderLink(item, this.props.handleMenuClick, klass);
        })}
      </React.Fragment>
    );
  }

  applyMarginBasedOnRoute(pathname) {
    if (pathname === '/franchising') {
      return isMobile() ? { marginTop: '1rem' } : { marginTop: '4rem' };
    } else if (
      pathname === '/pricing' ||
      pathname === '/appointment/locations' ||
      pathname.includes('/appointment/location') ||
      pathname === '/appointments' ||
      pathname === '/my-therapists' ||
      pathname === '/profile' ||
      pathname === '/invite' ||
      pathname === '/how-it-works' ||
      pathname === '/login' ||
      pathname === '/register' ||
      pathname === '/careers' ||
      pathname === '/about-us' ||
      pathname === '/privacy-policy' ||
      pathname === '/terms-of-use' ||
      pathname === '/preferences/summary' ||
      pathname === '/appointment/booked'
    ) {
      return isMobile() ? { marginTop: '0rem' } : { marginTop: '-3rem' };
    } else if (pathname === '/membership') {
      return isMobile() ? { marginTop: '1rem' } : { marginTop: '0rem' };
    } else if (pathname === '/membership/purchase-complete') {
      return isMobile() ? { marginTop: '3rem' } : { marginTop: '4rem' };
    }
    return {};
  }

  render() {
    return (
      <div
        id="navMenu"
        className="nav"
        style={this.applyMarginBasedOnRoute(this.props.location.pathname)}
      >
        <div className="nav__container container">
          <div className="nav__page-links logged-out-txt">
            {this.isLoggedIn()
              ? this.renderMemberMenu()
              : this.renderAnonymousMenu()}

            <div className="nav__page-link nav__page-link--social">
              <a
                href="https://www.facebook.com/squeezemassage/"
                target="_blank"
                onClick={() => {
                  analytics('Menu - Facebook', {
                    'Logged In': this.isLoggedIn() ? 'Yes' : 'No',
                  });
                }}
              >
                <i className="icon icon--facebook" />
              </a>
              <a
                href="https://twitter.com/squeezemassage"
                target="_blank"
                onClick={() => {
                  analytics('Menu - Twitter', {
                    'Logged In': this.isLoggedIn() ? 'Yes' : 'No',
                  });
                }}
              >
                <i className="icon icon--twitter" />
              </a>
              <a
                href="https://www.pinterest.com/squeezemassage/"
                target="_blank"
                onClick={() => {
                  analytics('Menu - Pinterest', {
                    'Logged In': this.isLoggedIn() ? 'Yes' : 'No',
                  });
                }}
              >
                <i className="icon icon--pinterest" />
              </a>
              <a
                href="https://www.instagram.com/squeeze/"
                target="_blank"
                onClick={() => {
                  analytics('Menu - Instagram', {
                    'Logged In': this.isLoggedIn() ? 'Yes' : 'No',
                  });
                }}
              >
                <i className="icon icon--instagram" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Mainmenu.propTypes = {
  user: PropTypes.object,
  membershipData: PropTypes.object,
  location: PropTypes.object,
  appointmentData: PropTypes.object,
  logoutUser: PropTypes.func,
  handleMenuClick: PropTypes.func,
  history: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  appointmentData: makeSelectAppointment(),
  user: makeSelectUser(),
  membershipData: makeSelectMembership(),
});

const withConnect = connect(
  mapStateToProps,
  { logoutUser },
);

export default compose(
  withConnect,
  withRouter,
)(Mainmenu);
