/* eslint-disable prefer-const */
/* eslint-disable camelcase */
import Config from 'app.config';
import { isEmpty } from 'lodash-es';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getCookie, lsTest, setCookie } from 'utils/localstore';
import {
  addKlaviyoMemberFailure,
  addKlaviyoMemberSuccess,
  checkUserEmailFailure,
  checkUserEmailSuccess,
  clearUser,
  createUserCardFailure,
  createUserCardSuccess,
  fetchUserCardsFailure,
  fetchUserCardsSuccess,
  fetchUserFailure,
  fetchUserSuccess,
  fetchUserTransactionsFailure,
  fetchUserTransactionsSuccess,
  logoutUser,
  setUser,
  updateUserCardSuccess,
  updateUserFailure,
  updateUserSuccess,
} from './actions';

import { clearMembershipData } from '../Membership/actions';

import {
  fetchAppointments,
  fetchLocations,
  fetchStatus,
} from '../Appointment/actions';

import {
  errorHandling,
  sagaErrorHandling,
  segmentIdentify,
  yieldErrorHandling,
} from '../../utils/common';
import {
  ADD_KLAVIYO_MEMBER,
  CHANGE_PASSWORD,
  CHECK_USER_EMAIL,
  CREATE_USER_CARD,
  FETCH_USER,
  FETCH_USER_CARDS,
  FETCH_USER_TRANSACTIONS,
  FORGOT_PASSWORD_LINK,
  LOGIN_USER,
  LOGOUT_USER,
  RESET_PASSWORD,
  SIGNUP_USER,
  UPDATE_USER,
  UPDATE_USER_CARD,
} from './constants';
import { makeSelectUser, makeSelectUserData } from './selectors';

import request, { buildRequestObj } from '../../utils/request';

function redirectToUserLastPath(action, result = null, redirectFunc = 'push') {
  const currentPath = window.location.pathname;
  let toPath;
  if (action.toPath) {
    toPath = action.toPath;
  } else {
    toPath = result.url;
  }
  let searchParams;
  if (toPath) {
    const splitPath = toPath.split('?', 2);
    [toPath] = splitPath;
    searchParams = splitPath[1] ? `?${splitPath[1]}` : '';
  }

  redirectFunc === 'push'
    ? action.history.push(toPath)
    : action.history.replace({
        pathname: toPath,
        search: searchParams,
        state: action.routeState,
      });
  if (action.resolve) action.resolve();
}

function* setUserAfterValidation(result, action) {
  const userData = { ...result.user };
  userData.token = result.token;
  const { id, token, email } = userData;
  // setUser
  yield put(setUser(userData));

  segmentIdentify(userData);

  // setlocalstorage token for persistant login
  if (lsTest()) {
    localStorage.setItem('userToken', token.replace('Bearer', ''));
    localStorage.setItem('userTokenCreatedAt', new Date());
    localStorage.setItem('userId', id);
    localStorage.setItem('email', email);
  } else {
    setCookie('userToken', token.replace('Bearer', ''), 30);
    setCookie('userTokenCreatedAt', new Date().toUTCString(), 30);
    setCookie('userId', id, 30);
  }
  // yield put(fetchMembershipDetails());
  yield put(fetchAppointments());
  yield put(fetchStatus());
  yield put(fetchLocations());
  redirectToUserLastPath(action, userData, 'replace');

  if (action.resolve) action.resolve();
}

function* clearUserAfterLogout(result, action) {
  yield call(() => action.history.push('/'));
  yield call(clearUserInLocalStore);
  yield put(clearUser());
  yield put(clearMembershipData());
  yield put({ type: 'RESET_ACTION' });
  if (action.resolve) action.resolve();
}

function clearUserInLocalStore() {
  if (lsTest()) {
    localStorage.removeItem('userToken');
    localStorage.removeItem('userBasicDetails');
    localStorage.removeItem('userTokenCreatedAt');
    localStorage.removeItem('userId');
    localStorage.removeItem('email');
    window.localStorage.removeItem('isNewUser');
    window.localStorage.removeItem('isBuyingFoundingMembership');
    window.localStorage.removeItem('isBuyingGift');
    window.localStorage.removeItem('isBuyingMembership');
    window.localStorage.removeItem('isDirectBookingAppt');
  } else {
    document.cookie =
      'userToken=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;';
    document.cookie =
      'userTokenCreatedAt=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;';
    document.cookie = 'userId=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;';
  }
}

function* login(action) {
  const {
    userData: { email, password },
    formProps,
  } = action;

  const reqObj = buildRequestObj('POST', {
    email,
    password,
    source: 'customer',
  });
  const reqUrl = `${Config.apiBasev2}/login`;
  try {
    const result = yield call(request, reqUrl, reqObj);
    const { user } = result;
    localStorage.setItem(
      'userBasicDetails',
      JSON.stringify({
        first_name: `${user.first_name}`,
        last_name: `${user.last_name}`,
        email: user.email,
        phone: user.phone
          ? user.phone
              .replace(/\D+/g, '')
              .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
          : '',
      }),
    );
    yield call(formProps.setSubmitting, false);
    yield setUserAfterValidation(result, action);
  } catch (error) {
    yield call(formProps.setSubmitting, false);
    yield call(formProps.setErrors, {
      submissionError: errorHandling(error) || 'Unable to Login',
    });
  }
}

function* watchLogin() {
  yield takeLatest(LOGIN_USER, login);
}

export function* logout(action) {
  let userId;
  if (lsTest()) {
    userId = Number(localStorage.getItem('userId'));
  } else {
    userId = Number(getCookie('userId'));
  }

  const reqObj = buildRequestObj('POST', {}, true);
  const reqUrl = `${Config.apiBasev2}/logout`;

  // yield commonSagaLogic(
  //   action,
  //   requestOptions,
  //   requestPath,
  //   'Logout failed',
  //   clearUserAfterLogout,
  // );

  try {
    const result = yield call(request, reqUrl, reqObj);
    yield clearUserAfterLogout(result, action);
  } catch (error) {
    yield clearUserAfterLogout(null, action);
  }
}

function* watchLogout() {
  yield takeLatest(LOGOUT_USER, logout);
}

export function* signupSaga(action) {
  let {
    userData: {
      first_name,
      last_name,
      email,
      password,
      dob,
      reminder_opt_in,
      phone,
    },
    formProps,
  } = action;

  // modifying data
  phone = phone.replace(/[^a-zA-Z0-9]/g, '');
  dob = dob.replace(/\//g, '-');
  reminder_opt_in = +reminder_opt_in;

  const reqObj = buildRequestObj(
    'POST',
    {
      first_name,
      last_name,
      email,
      password,
      dob,
      reminder_opt_in,
      phone,
    },
    true,
  );
  const reqUrl = `${Config.apiBasev2}/register`;
  try {
    const result = yield call(request, reqUrl, reqObj);
    const { user } = result;
    yield call(formProps.setSubmitting, false);
    yield setUserAfterValidation(result, action);
    localStorage.setItem(
      'userBasicDetails',
      JSON.stringify({
        first_name: `${user.first_name}`,
        last_name: `${user.last_name}`,
        email: user.email,
        phone: user.phone
          ? user.phone
              .replace(/\D+/g, '')
              .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
          : '',
      }),
    );
    // call referral apply api
    // yield call(applyRefferalTrack);
  } catch (error) {
    yield call(formProps.setSubmitting, false);
    yield call(formProps.setErrors, {
      submissionError: errorHandling(error) || 'Signup failed',
    });
  }
}

function* watchSignup() {
  yield takeLatest(SIGNUP_USER, signupSaga);
}

export function* fetchUserSaga(action) {
  const reqObj = buildRequestObj('GET', {}, true);
  const id = localStorage.getItem('userId');
  const reqUrl = `${Config.apiBasev2}/users`;
  try {
    const result = yield call(request, reqUrl, reqObj);
    const userData = result.items[0];
    yield put(fetchUserSuccess(userData));
    if (action.redirectAfterFetch) {
      redirectToUserLastPath(action, userData, 'push');
    }
  } catch (error) {
    yield yieldErrorHandling(error, fetchUserFailure, 'user fetch failed');
    yield call(clearUserInLocalStore);
    yield put(clearUser());
  }
}

function* watchFetchUser() {
  yield takeLatest(FETCH_USER, fetchUserSaga);
}

// get cards
export function* fetchUserCardsSaga(action) {
  const reqObj = buildRequestObj('GET', {}, true);
  const reqUrl = `${Config.apiBasev2}/user/cards`;

  try {
    const result = yield call(request, reqUrl, reqObj);
    // TODO: typo in API response itmes
    yield put(fetchUserCardsSuccess(result.items));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      fetchUserCardsFailure,
      'user card fetch failed',
    );
  }
}

function* watchFetchUserCards() {
  yield takeLatest(FETCH_USER_CARDS, fetchUserCardsSaga);
}

// createCard
export function* createUserCardSaga(action) {
  const paymentMethodId = action.stripe_token;
  const reqObj = buildRequestObj(
    'POST',
    {
      paymentMethodId,
    },
    true,
  );
  const reqUrl = `${Config.apiBasev2}/user/card`;
  try {
    const result = yield call(request, reqUrl, reqObj);
    yield put(createUserCardSuccess({ success: true }));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      createUserCardFailure,
      'user card creation failed',
    );
  }
}

function* watchCreateUserCard() {
  yield takeLatest(CREATE_USER_CARD, createUserCardSaga);
}

// update card
export function* updateUserCardSaga(action) {
  const paymentMethodId = action.card_id;

  const reqObj = buildRequestObj(
    'PUT',
    {
      paymentMethodId,
    },
    true,
  );
  const reqUrl = `https://squeeze-api2-qa.herokuapp.com/v2/user/card/default`;

  // TODO: Figure out a better solution for NOT calling this api and keeping the state unmodified
  yield put(updateUserCardSuccess({ success: true }));
  // try {
  //   const result = yield call(request, reqUrl, reqObj);
  //   yield put(updateUserCardSuccess({ success: true }));
  // } catch (error) {
  //   yield yieldErrorHandling(
  //     error,
  //     updateUserCardFailure,
  //     'user card update failed',
  //   );
  // }
}

function* watchUpdateUserCard() {
  yield takeLatest(UPDATE_USER_CARD, updateUserCardSaga);
}

function* forgotPasswordSaga(action) {
  const { email } = action;

  const requestOptions = buildRequestObj('POST', {
    email,
  });
  const requestPath = `${Config.apiBasev2}/forgot-password`;
  try {
    const result = yield call(request, requestPath, requestOptions);
    redirectToUserLastPath(action, null, 'replace');
  } catch (error) {
    yield sagaErrorHandling(
      error,
      action.reject,
      'password reset link send failed',
    );
  }
}

function* watchForgotPassword() {
  yield takeLatest(FORGOT_PASSWORD_LINK, forgotPasswordSaga);
}

function* resetPasswordSaga(action) {
  const { password, token, password_confirmation, onSuccess } = action;
  const user = yield select(makeSelectUser());
  const requestOptions = buildRequestObj('POST', {
    password,
    token,
    password_confirmation,
  });
  const requestPath = `${Config.apiBasev2}/reset-password`;
  try {
    const result = yield call(request, requestPath, requestOptions);
    if (isEmpty(user.userData)) {
      yield put(logoutUser());
    }
    yield call(onSuccess);
  } catch (error) {
    yield sagaErrorHandling(error, action.reject, 'password reset failed');
  }
}

function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD, resetPasswordSaga);
}

export function* updateUserSaga(action) {
  const { userData } = action;
  const userDetails = yield select(makeSelectUserData());

  // modifying data
  if (userData.phone) {
    userData.phone = userData.phone.replace(/[^a-zA-Z0-9]/g, '');
  }
  // dob = dob.replace(/\//g, '-');
  // reminder_opt_in = +reminder_opt_in;

  const reqObj = buildRequestObj('PUT', userData, true);
  const reqUrl = `${Config.apiBasev2}/user`;
  try {
    const result = yield call(request, reqUrl, reqObj);
    yield put(fetchUserSuccess(result));
    yield put(setUser({ ...userDetails, ...result.user }));
    yield put(updateUserSuccess({ success: true }));
  } catch (error) {
    yield yieldErrorHandling(error, updateUserFailure, 'user update failed');
  }
}

function* watchUpdateUser() {
  yield takeLatest(UPDATE_USER, updateUserSaga);
}

export function* fetchUserTransactionsSaga(action) {
  const reqObj = buildRequestObj('GET', {}, true);
  const reqUrl = `${Config.apiBasev2}/transactions`;
  try {
    const result = yield call(request, reqUrl, reqObj);
    yield put(fetchUserTransactionsSuccess(result.items));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      fetchUserTransactionsFailure,
      'user transactions fetch failed',
    );
  }
}
function* watchFetchUserTransactions() {
  yield takeLatest(FETCH_USER_TRANSACTIONS, fetchUserTransactionsSaga);
}

function* changePasswordSaga(action) {
  const { password, password_confirmation, onSuccess } = action;
  const requestOptions = buildRequestObj(
    'POST',
    {
      password,
      password_confirmation,
    },
    true,
  );
  const requestPath = `${Config.apiBasev2}/reset-password`;
  try {
    yield call(request, requestPath, requestOptions);
    yield call(onSuccess);
  } catch (error) {
    yield sagaErrorHandling(error, action.reject, 'password change failed');
  }
}

function* watchChangePassword() {
  yield takeLatest(CHANGE_PASSWORD, changePasswordSaga);
}

export function* checkUserEmailSaga(action) {
  const { email } = action;

  const reqObj = buildRequestObj('GET', {}, false);
  const reqUrl = `${Config.apiBasev2}/user-exist?email=${email}`;
  try {
    const result = yield call(request, reqUrl, reqObj);
    yield put(checkUserEmailSuccess({ ...result }));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      checkUserEmailFailure,
      'User email check failed',
    );
  }
}
function* watchCheckUserEmail() {
  yield takeLatest(CHECK_USER_EMAIL, checkUserEmailSaga);
}

// add member to klaviyo global list

export function* addKlaviyoMemberSaga(action) {
  const {
    email,
    zip,
    completedAppointments,
    upComingAppointments,
  } = action.payload;

  const custom_properties = {
    'Completed Appointments': completedAppointments,
    'Upcoming Appointments': upComingAppointments,
    zip,
  };
  const listId = 'Me8sXZ';

  const reqObj = buildRequestObj(
    'PUT',
    { custom_properties, email, listId },
    false,
  );
  const reqUrl = `${Config.apiBasev2}/user/klaviyo/custom_property`;
  try {
    const result = yield call(request, reqUrl, reqObj);
    yield put(addKlaviyoMemberSuccess({ success: true }));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      addKlaviyoMemberFailure,
      'add member klaviyo failed',
    );
  }
}

function* watchAddKlaviyoMember() {
  yield takeLatest(ADD_KLAVIYO_MEMBER, addKlaviyoMemberSaga);
}

export default {
  watchFetchUser,
  watchSignup,
  watchLogin,
  watchLogout,
  watchCreateUserCard,
  watchUpdateUserCard,
  watchFetchUserCards,
  watchForgotPassword,
  watchResetPassword,
  watchUpdateUser,
  watchFetchUserTransactions,
  watchChangePassword,
  watchCheckUserEmail,
  watchAddKlaviyoMember,
};
