/* eslint-disable camelcase */
/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
// Import all the third party stuff
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import { isEmpty } from 'lodash-es';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import 'sanitize.css/sanitize.css';

// Import root app
import ErrorBoundary from 'components/ErrorBoundary';
import App from 'containers/App';
import User from 'containers/User';
// Import CSS reset and Global Styles
import 'css/about.css';
import 'css/account.css';
import 'css/advisors.css';
import 'css/appointment.css';
import 'css/careers.css';
import 'css/franchise.css';
import 'css/gallery.css';
import 'css/generic.css';
import 'css/how-it-works.css';
import 'css/index.css';
import 'css/press.css';
import 'css/pricing.css';
import 'css/profile.css';
import 'css/style-guide.css';
import 'css/style.css';

// Load the favicon and the .htaccess file
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import 'file-loader?name=[name].[ext]!./.htaccess'; // eslint-disable-line import/extensions

import configureStore from './configureStore';
import { loadState } from './utils/localStorage_persist';
// Import i18n messages
import { translationMessages } from './i18n';

// Import CSS reset and Global Styles
import Config from './app.config';
import './global-styles';
// eslint-disable-next-line import/extensions
import { setBranchDisountCode } from './utils/common';
import GoogleMapsInit from './utils/third-party-plugins/google-maps-init';
const { branchApiKey, sentryDsn } = Config;

Sentry.init({
  dsn: sentryDsn,
});

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: sentryDsn,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.Replay(), new Sentry.BrowserTracing()],
  });
} else {
  Sentry.init({
    dsn: sentryDsn,
  });
}

GoogleMapsInit();

const detectDiscountCode = data => {
  if (data && !isEmpty(data.data_parsed.discount)) {
    setBranchDisountCode(data);
  }
};

// eslint-disable-next-line no-undef
try {
  window.branch.init(branchApiKey, (e, data) => {
    if (data) {
      if (data.data_parsed['~referring_link']) {
        localStorage.setItem(
          'referral_link',
          data.data_parsed['~referring_link'],
        );
      }
    }
    if (!e) detectDiscountCode(data);
  });
} catch (err) {
  // eslint-disable-next-line no-empty
}

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
// const openSansObserver = new FontFaceObserver('Open Sans', {});

// When Open Sans is loaded, add a font-family using Open Sans to the body
// openSansObserver.load().then(() => {
//   document.body.classList.add('fontLoaded');
// });

// Create redux store with history
const persistedState = loadState();
const initialState = persistedState || {};
const history = createBrowserHistory();
const store = configureStore(initialState, history);

const MOUNT_NODE = document.getElementById('app');

// eslint-disable-next-line
const analytics = (window.analytics = window.analytics || []);
try {
  analytics.load(Config.segmentApiKey);
} catch (err) {
  // Catch error for analytics
}

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ErrorBoundary>
          <User>
            <App />
          </User>
        </ErrorBoundary>
      </ConnectedRouter>
    </Provider>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([
        import('intl/locale-data/jsonp/en.js'),
        import('intl/locale-data/jsonp/de.js'),
      ]),
    )
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
// if (process.env.NODE_ENV !== 'development') {
//   // eslint-disable-next-line global-require
//   require('offline-plugin/runtime').install({
//     excludes: ['**/.*', '**/*.map', '**/*.gz'],
//   }); // eslint-disable-line global-require
// }
